@tailwind base;
@tailwind components;
@tailwind utilities;

#__next {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@layer utilities {
  .visually-hidden {
    position: absolute !important;
    left: -99999rem !important;
  }
}
